.login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  margin: 0;
  background: linear-gradient(135deg, #f6f9fc 0%, #ffffff 100%);
}

/* Card wrapper */
.login-card {
  background-color: #fff;
  width: 420px;
  max-width: 90%;
  border-radius: 12px;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.08);
  padding: 2.5rem;
  text-align: center;
  animation: fadeIn 0.3s ease-in-out;
}

/* Smooth fade-in */
@keyframes fadeIn {
  0% { opacity: 0; transform: translateY(5px); }
  100% { opacity: 1; transform: translateY(0); }
}

/* Logo */
.login-logo {
  width: 80px;
  margin-bottom: 1rem;
}

/* Modern header text */
.login-title {
  margin-bottom: 0.5rem;
  font-size: 1.7rem;
  font-weight: 700;
  color: #1c1c1c;
  line-height: 1.3;
}

/* Subtitle */
.login-subtitle {
  margin-bottom: 2rem;
  font-size: 1rem;
  color: #5f5f5f;
}

/* Google button */
.google-sign-in-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(90deg, #4285f4, #34a853);
  color: #fff;
  font-size: 1rem;
  font-weight: 600;
  border: none;
  border-radius: 50px;
  padding: 0.75rem 2rem;
  cursor: pointer;
  box-shadow: 0 4px 12px rgba(0,0,0,0.1);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.google-sign-in-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 18px rgba(0,0,0,0.15);
}

.google-sign-in-button img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

/* Error Message */
.error-message {
  color: #e53935;
  margin-bottom: 1.5rem;
}


.note {
  margin-top: 1rem;
  font-size: 0.9rem;
  color: #777;
}


.login-title {
  margin-bottom: 0.5rem;
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 1.3;
  background: linear-gradient(90deg, #ff512f, #dd2476);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
