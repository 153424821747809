/*   Popup chat bot ---------------------------
/* Chat button positioned in the bottom-right corner */
/* .chat-popup-button {
  position: fixed;
  bottom: 20px; 
  right: 40px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  z-index: 1000; 
}

.chat-popup-button:hover {
  background-color: #0056b3;
} */

.chat-popup-button {
  position: fixed;
  bottom: 20px;
  right: 40px;
  padding: 12px 24px;
  background: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(10px);
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.25);
  border-radius: 50px;
  font-size: 16px;
  cursor: pointer;
  z-index: 1000;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
}

.chat-popup-button:hover {
  background: rgba(255, 255, 255, 0.25);
}


/* Chat popup window positioned in the bottom-right corner */
.popup-box {
  position: fixed;
  bottom: 80px; /* Slightly above the chat button */
  right: 20px; /* Aligned with the chat button */
  width: 400px; /* Adjust width as needed */
  height: 597px;
  background-color: rgba(1, 137, 248, 0.65);
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1001; /* Ensure it's above other elements */
  overflow: hidden;
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease-in-out;
  animation: fadeInUp 0.3s ease-in-out;
  border: 1px solid #d0d0d0; /* Slightly darker border than background */

}
@keyframes fadeInUp {
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
}


/* Chat container with proper padding and layout */
.chat-container {
  display: flex;
  flex-direction: column;
  height: 123%;
  padding: 10px;
  box-sizing: border-box;
}

/* Scrollable chat history */
.chat-history {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  margin-bottom: 10px;
  padding-right: 10px;
}

/* User and assistant message styles */
.assistant-message {
  background-color: #007bff00;
  color: rgb(0, 0, 0);
  padding: 0px 0px;
  border-radius: 15px;
  margin-bottom: 5px;
  max-width: 100%;
  align-self: flex-start;
  margin-right: auto;
}

.user-message {
  background-color: #f1f1f1;
  color: black;
  padding: 8px 12px;
  border-radius: 15px;
  margin-bottom: 5px;
  max-width: 75%;
  align-self: flex-end;
  margin-left: auto;
}

/* Fixed input area at the bottom */
.chat-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0; /* Padding for space around the input */
  border-top: 1px solid #e3e3e3; /* Line above the input area */
  bottom: 0; /* Fixed at the bottom of the chat window */
  width: 100%;
  left: 0;
}

/* Input box styling */
.chat-input {
  width: 95%; /* Leave space for the send button */
  height: 40px;
  padding: 10px;
  border: 2px solid #e3e3e3;
  border-radius: 5px;
  resize: none;
}

/* Send button visible and aligned next to the input */
.chat-send-button {
  margin-left: 10px;
  padding: 8px 15px;
  background-color: #17a2b8; 
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.chat-send-button:hover {
  background-color: #138496;
}

/* Close button to close the chat */
.close-icon {
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
}


/* Style for the Add Content textarea */
textarea {
  width: 100%;
  min-height: 100px;
  border-radius: 8px;
  padding: 10px;
  border: 1px solid #ccc;
  margin-bottom: 10px;
  font-size: 16px;
  box-sizing: border-box;
}

/* Style for the Add Content button */
button[onClick="addContent"] {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 6px;
  cursor: pointer;
  font-size: 18px;
  transition: background-color 0.3s ease;
}

button[onClick="addContent"]:hover {
  background-color: #0056b3;
}

/* Container for Add Content section */
.add-content-container {
  max-width: 1200px;
  margin: 0 auto 20px;
  padding: 0 20px;
}
