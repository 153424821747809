/* ------------------------ App Styles ------------------------ */
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #343a40;
  color: #f8f9fa;
  font-size: 2.5rem;
  font-weight: bold;
  padding: 20px;
  margin-bottom: 40px;
  border-radius: 8px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.user-info {
  display: flex;
  align-items: center;
  width: 100%; /* Ensure the container takes up full width */
  margin-top: 20px;  /* Add additional top margin */
  margin-bottom: 1.5rem;
}

.welcome-text {
  flex-grow: 0; /* prevent it from expanding */
  margin-right: 20px;
}

.user-info img {
  margin-right: 1.5rem; /* Adds space between the profile picture and name */
}

.user-info button {
  margin-left: auto; /* Pushes the logout button to the far right */
}

.profile-image {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

.action-buttons {
  display: flex;
  gap: 5px;
  margin-left: auto; /* push to the right */
}

/* Additional styling for a modern look */
body {
  font-family: 'Roboto', sans-serif;
  background-color: #f8f9fa;
  margin: 0;
  color: #343a40;
}

/* .App {
  max-width: 1200px;
  margin: auto;
  padding: 40px;
  text-align: center;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 50px;
} */

.App {
  max-width: 1200px;
  margin: auto;
  padding: 50px;
  text-align: center;
  background: #ffffff;
  border-radius: 16px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.08);
  margin-bottom: 50px;
}


/* Style buttons and inputs */
/* Global button style */
/* button {
  background-color: #17a2b8;
  color: #fff;
  border: none;
  padding: 12px 20px;
  cursor: pointer;
  border-radius: 6px; 
  font-size: 16px;
  transition: background-color 0.3s ease;
} */

/* button:hover {
  background-color: #138496;
} */

button {
  background: linear-gradient(135deg, #00c6ff, #0072ff);
  color: #fff;
  padding: 12px 24px;
  border: none;
  border-radius: 30px;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
}

button:hover {
  transform: translateY(-1px) scale(1.02);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}



/* textarea, input {
  width: 100%;
  padding: 12px;
  margin-bottom: 15px;
  border-radius: 6px;
  border: 1px solid #ced4da;
  font-size: 16px;
  background-color: #f1f3f5;
} */

textarea, input {
  width: 100%;
  padding: 14px;
  margin-bottom: 15px;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  background: #fafafa;
  transition: border-color 0.3s;
}

textarea:focus, input:focus {
  outline: none;
  border-color: #0072ff;
  box-shadow: 0 0 4px rgba(0, 114, 255, 0.3);
}


/* Table Styling */
/* .content-table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  font-family: 'Roboto', sans-serif;
} */

.content-table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  font-family: 'Roboto', sans-serif;
  border-radius: 10px;
  overflow: hidden;
}

.content-table th, .content-table td {
  padding: 12px 15px;
  border-bottom: 1px solid #e0e0e0;
  background: #f7f9fc; 
  text-align: left;
}

.content-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.content-table tr:hover {
  background-color: #f0f4ff;
  transition: background-color 0.2s ease;
}


/* Title Input and Save Button Styling */
.title-row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.title-input-editing {
  background-color: lightblue;
  border: 1px solid #007bff;
  border-radius: 4px;
  padding: 4px;
  font-size: 16px;
}

/* Content Row styling */
.content-row {
  position: relative;
  display: inline-block;
  cursor: pointer;
  word-wrap: break-word;
}

.content-row .title {
  font-size: 18px;
  font-weight: 600;
  color: #333;
}

.content-row .description {
  font-size: 14px;
  color: #555;
}

/* Delete button */
.delete-button {
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
}

.delete-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.page-wrapper {
  margin-bottom: 50px; /* Add margin-bottom to the page wrapper */
}

/* Timestamp styling */
.timestamp {
  float: right;
  font-size: 12px;
  color: gray;
}

/* Content row dragging */
tr[draggable="true"] {
  cursor: move;
}

tr[draggable="true"]:hover {
  background-color: #f1f1f1;
}

.section-card {
  background: #ffffff;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
}


/* ------------------------ Folder ------------------------ */
.app-container {
  display: flex;
  height: 100vh;
}

.app-main {
  display: flex;
  width: 100%;
}

.content-section {
  padding: 20px;
  width: 100%;
  overflow-y: auto;
}

/* ============== Add these styles to handle the move dropdown menu */

.move-dropdown {
  position: relative;
  display: inline-block;
  margin-left: 10px;
}

.move-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 4px 8px;
  cursor: pointer;
  border-radius: 4px;
  font-size: 12px;
}

.move-button:hover {
  background-color: #0056b3;
}

.move-dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 120px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.move-dropdown-content a {
  color: black;
  padding: 8px 12px;
  text-decoration: none;
  display: block;
  font-size: 14px;
}

.move-dropdown-content a:hover {
  background-color: #ddd;
}

.move-dropdown:hover .move-dropdown-content {
  display: block;
}

.move-dropdown:hover .move-button {
  background-color: #0056b3;
}

/* Optional: Adjust the "Actions" column to align properly */
.content-table td {
  vertical-align: top;
}

.title-row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.title-input-editing {
  background-color: lightblue;
  border: 1px solid #007bff;
  border-radius: 4px;
  padding: 4px;
  font-size: 16px;
}

.content-row a {
  margin-left: 5px;
  color: #007bff;
  text-decoration: none;
  font-size: 14px;
}

.content-row a:hover {
  text-decoration: underline;
}

.move-dropdown a {
  cursor: pointer;
}


/* Pagination container to center the page numbers */
.pagination {
  display: flex;
  justify-content: center;
  margin: 20px 0;
  list-style-type: none;
  padding: 0;
}

/* Style each page number */
.page-number {
  margin: 0 10px; /* Adds space between page numbers */
  cursor: pointer;
  font-size: 18px;
}

/* Style for the active/current page */
.page-number.active {
  color: red;
  text-decoration: underline;
}

/* Add hover effect for page numbers */
.page-number:hover {
  color: darkred;
}


tr[draggable="true"] {
  background-color: transparent !important;
}

tr[draggable="true"]:active {
  opacity: 0.5;
}


/* Modal Overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: #33333382; /* Dark background for the modal */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.537);
  max-width: 500px;
  max-width: 800px;
  width: 100%;
}

.modal-content h3 {
  margin-top: 0;
  color: white;
}

.folder-options {
  max-height: 300px;
  overflow-y: auto;
  margin: 15px 0;
}

.folder-option-button {
  background: none;
  border: none;
  padding: 5px 10px;
  margin: 2px 0;
  width: 100%;
  text-align: left;
  cursor: pointer;
  transition: background-color 0.2s, color 0.2s, padding 0.2s; /* Add transition for padding */
  color: white;
}

.folder-option-button:hover {
  background-color: #f0f0f0; /* Light background on hover */
  color: black; /* Black text on hover */
  padding-left: 20px; /* Increase left padding on hover */
}

.close-modal-button {
  background-color: #f44336;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
  float: right;
}

.close-modal-button:hover {
  background-color: #d32f2f;
}


/* Folder Option Buttons */
.folder-option-button {
  background: none;
  border: none;
  text-align: left;
  padding: 8px 0;
  width: 100%;
  cursor: pointer;
  font-size: 14px;
}

.folder-option-button:hover {
  background-color: #f0f0f0;
  border-radius: 4px;
}

/* Additional Styling for Buttons */
.folder-option-button:focus {
  outline: none;
  background-color: #e0e0e0;
}

/* Ensure the modal-content doesn't exceed the viewport on small screens */
@media (max-width: 400px) {
  .modal-content {
    width: 90%;
    color: white;
  }
}

/*   Popup chat bot ---------------------------
/* Chat button positioned in the bottom-right corner */
.chat-popup-button {
  position: fixed;
  bottom: 20px;
  right: 40px;
  width: 200px;
  height: 40px;
  background-color: #007bff !important;
  color: white !important;
  border: none !important;
  border-radius: 25px !important;
  font-size: 16px !important;
  cursor: pointer;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 !important;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.chat-popup-button:hover {
  background-color: #0056b3;
}

/* Chat popup window positioned in the bottom-right corner */
.popup-box {
  position: fixed;
  bottom: 70px;
  right: 20px;
  width: 400px;
  /* Remove the fixed height */
  max-height: 90vh;  /* Instead of a fixed height, limit it to a percentage of the viewport height */
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1001;
  display: flex;
  flex-direction: column;
  border: 1px solid #d0d0d0;
  overflow: hidden;
}

/* Ensure no additional margins/padding affects height */
.popup-box * {
  margin: 0;
  padding: 0;
}

/* Adjust the chat container */
.chat-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

/* Scrollable chat history */
.chat-history {
  flex: 1;
  height: auto; /* Remove max-height to allow it to grow dynamically */
  overflow-y: auto;
  padding: 10px;
  box-sizing: border-box;
}

/* Input area at the bottom */
.chat-box {
  display: flex;
  padding: 10px;
  border-top: 1px solid #e3e3e3;
}

/* Input box styling */
.chat-input {
  flex-grow: 1;
  height: 40px;
  padding: 10px;
  border: 2px solid #e3e3e3;
  border-radius: 5px;
  resize: none;
  margin-right: 10px;
}

/* Send button visible and aligned next to the input */
.chat-send-button {
  padding: 8px 15px;
  background-color: #17a2b8;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.chat-send-button:hover {
  background-color: #138496;
}

/* Close button to close the chat */
.close-icon {
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  color: #333;
}


/* Fullscreen container with a light gradient background */
.subscription-prompt {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  margin: 0;
  background: linear-gradient(135deg, #f6f9fc 0%, #ffffff 100%);
}

/* Card container with subtle shadow and rounded corners */
.subscription-card {
  background-color: #fff;
  width: 420px;
  max-width: 90%;
  border-radius: 12px;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.08);
  padding: 2.5rem;
  text-align: center;
  animation: fadeIn 0.3s ease-in-out; /* optional fade-in */
}

/* Title text */
.subscription-card h2 {
  font-size: 1.8rem;
  margin-bottom: 1rem;
  font-weight: 700;
  line-height: 1.3;
  color: #1c1c1c;
}

/* Subtle subtitle styling */
.subscription-card .subtitle {
  font-size: 1rem;
  margin-bottom: 1.5rem;
  color: #5f5f5f;
}

/* Space around the buy button */
.buy-button-container {
  margin: 2rem 0;
}

/* Fade-in keyframe */
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(5px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* The logout button styling */
.logout-button {
  margin-top: 2rem;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 4px;
  background-color: #e53935; /* a nice red */
  color: #fff;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.2s;
}
.logout-button:hover {
  background-color: #c62828;
}

/* Fade-in animation (optional) */
@keyframes fadeIn {
  0% { opacity: 0; transform: translateY(10px); }
  100% { opacity: 1; transform: translateY(0); }
}
